import React from "react";
import AdminLayout from "../../LayoutWrapper/AdminLayout";
import { Col, Container, Row } from "react-bootstrap";
import "../../CSS/About/index.css";
import { useTranslation } from "react-i18next";
import profile from "../../Images/About/profile.jpg";

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <AdminLayout>
      <section className="aboutBanner py-100 bgImg">
        <Container>
          <Row>
            <Col>
              <h1 className="pageTitle text-center text-white">
                {t("page_title_about")}
              </h1>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="bannerBelowContent pt-5 pb-5">
        <Container>
          <Row>
            <Col>
              <h2 className="section-title text-center mb-4">
                {t("about_section_title1")}
              </h2>
              <p className="paragraph">{t("about_section_para1")}</p>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <section className="profileSection pt-lg-0 pt-5">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="profile-img-wrapper">
                <img src={profile} alt="" className="profile-img" />
              </div>
            </Col>
            <Col lg={6}>
              <p className="paragraph pt-lg-0 pt-3"> {t("dummy_para")}</p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="profileSection py-5">
        <Container>
          <Row className="flex-lg-row flex-column-reverse">
            <Col lg={6}>
              <p className="paragraph pt-lg-0 pt-3"> {t("dummy_para")}</p>
            </Col>
            <Col lg={6}>
              <div className="profile-img-wrapper">
                <img src={profile} alt="" className="profile-img" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="profileSection pb-5">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="profile-img-wrapper">
                <img src={profile} alt="" className="profile-img" />
              </div>
            </Col>
            <Col lg={6}>
              <p className="paragraph pt-lg-0 pt-3"> {t("dummy_para")}</p>
            </Col>
          </Row>
        </Container>
      </section> */}
    </AdminLayout>
  );
};

export default AboutUs;
