import React from 'react'
import AdminLayout from '../../LayoutWrapper/AdminLayout'
import google from '../../Images/Blog/google-play.png'

export default function Users() {
  return (
    <AdminLayout>
    <div>
    <div className='better-driver'>
      <h3 style={{textAlign:"center",fontWeight:"600"}}>For better performance please download our user app from playstore</h3>
      <a href='https://play.google.com/store/apps/details?id=com.comforttrip.user'><div style={{padding:"2rem",display:"flex",justifyContent:"space-evenly",alignItems:"center"}}><img style={{width:"15%"}} src={google}/></div></a>
    
    </div>
    </div>
    </AdminLayout>
  )
}
