import React from "react";
import { useTranslation } from "react-i18next";
import AdminLayout from "../../LayoutWrapper/AdminLayout";

const CookiesPolicy = () => {
  const { t } = useTranslation();

  return (
    <AdminLayout>
      <section className="cookies-wrapper py-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="page-title text-center">
                {t("page_title_cookies_policy")}
              </h1>

              <div className="comfort-body-content py-md-5 py-3">
                <h2 className="point-title mt-md-4 mt-2 mb-3">
                  {t("cookies_title1")}
                </h2>
                <p className="page-para">{t("cookies_para1")}</p>
                <h2 className="point-title mt-4 mb-3">{t("cookies_title1")}</h2>
                <p className="page-para">{t("cookies_para1")}</p>
                <h2 className="point-title mt-4 mb-3">{t("cookies_title1")}</h2>
                <p className="page-para">{t("cookies_para1")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </AdminLayout>
  );
};

export default CookiesPolicy;
