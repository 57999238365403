import React, { useState } from "react";
import Button from "react-bootstrap/Button";
// import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
// import Row from "react-bootstrap/Row";
import AdminLayout from "../../LayoutWrapper/AdminLayout";
import "../../CSS/UserSignup/index.css";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Col, Container, Row } from "react-bootstrap";
import PhoneCodeFiled from "./phone";
import { useTranslation } from "react-i18next";
import { postApihandler } from "../../ApiHandler";
import swal from "sweetalert";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
const DriverSignup = () => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordup, setShowPasswordup] = useState(false);
  const [validated, setValidated] = useState(false);
  const [fullName, setFullName] = useState();
  const [email, setEmail] = useState();
  const [countryCode, setCountryCode] = React.useState("+91");
  const [mobileNumber, setMobileNumber] = useState();
  const [password, setpassword] = useState();
  const [cnfmpassword, setCNFMPassword] = useState();
  const { register, handleSubmit, reset, setValue, watch } = useForm();

  const SubmitSignup = async (event) => {
    const signupData = {
      fullName: fullName,
      email: email,
      countryCode: countryCode,
      phoneNumber: mobileNumber,
      password: password,
      confirmPassword: cnfmpassword,
    };

    const postRes = await postApihandler("/driverSignup", signupData);
    console.log("postRes=====>", postRes);
    if (postRes.status == 200) {
      swal({
        icon: "success",
        text: "Congratulations! Your account has been created successfully!",
      });
    } else {
      swal({
        icon: "error",
        title: postRes.error.response.data.message,
        text: "Please Try Again",
      });
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibilityUp = () => {
    setShowPasswordup(!showPasswordup);
  };

  return (
    <AdminLayout>
      <section className="signupFormWrapper d-flex mb-5 flex-lg-row flex-column">
        <div className="form-wrapper-column-1">
          <div className="imgWrapper bgImg h-100"></div>
        </div>
        <div className="form-Data-Wrapper form-wrapper-column-2 py-4 px-5">
          <h3 className="signupTitle mb-4">{t("page_title_driverSignup")}</h3>
          <div className="form-field-wrapper">
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit(SubmitSignup)}
            >
              <Row className="mb-4">
                <Form.Group as={Col} controlId="validationCustom01">
                  <Form.Control
                    required
                    type="text"
                    placeholder={t("form_fullName_text")}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please fill a fullname.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-4">
                <Form.Group as={Col} controlId="validationCustomUsername">
                  <InputGroup hasValidation>
                    <Form.Control
                      type="email"
                      placeholder={t("form_email_text")}
                      aria-describedby="inputGroupPrepend"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please fill a email.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className="mb-4">
                <Form.Group
                  as={Col}
                  md="3"
                  controlId="validationCustomUsername"
                >
                  <PhoneCodeFiled setCountryCode={setCountryCode} />
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="9"
                  controlId="validationCustomUsername"
                >
                  <InputGroup hasValidation>
                    <Form.Control
                      type="tel"
                      placeholder={t("form_mobile_text")}
                      aria-describedby="inputGroupPrepend"
                      required
                      onChange={(e) => setMobileNumber(e.target.value)}
                    />

                    <Form.Control.Feedback type="invalid">
                      Please fill a mobile number.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>{" "}
              </Row>
              <Row className="mb-4">
                <Form.Group
                  as={Col}
                  md="6"
                  className="mb-md-0 mb-4 password-group"
                  controlId="validationCustom03"
                >
                  <Form.Control
          type={showPassword ? "text" : "password"}
          placeholder={t("form_password_text")}
          required
          onChange={(e) => setpassword(e.target.value)}
          className="password-input"
        />
        <div className="password-icon" onClick={togglePasswordVisibility}>
          {showPassword ? <FaEyeSlash /> : <FaEye />}
        </div>
        <Form.Control.Feedback type="invalid">
          Please fill a valid password.
        </Form.Control.Feedback>
                </Form.Group>
                {/* <Form.Group as={Col} md="6" controlId="validationCustom04">
                  <Form.Control
                    type="password"
                    placeholder={t("form_confirm_password_text")}
                    required
                    onChange={(e) => setCNFMPassword(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please fill a valid password.
                  </Form.Control.Feedback>
                </Form.Group> */}
                <Form.Group
                  as={Col}
                  md="6"
                  className="mb-md-0 mb-4 password-group"
                  controlId="validationCustom03"
                >
                  <Form.Control
          type={showPasswordup ? "text" : "password"}
          placeholder={t("form_confirm_password_text")}
          required
          onChange={(e) => setCNFMPassword(e.target.value)}
          className="password-input"
        />
        <div className="password-icon" onClick={togglePasswordVisibilityUp}>
          {showPasswordup ? <FaEyeSlash /> : <FaEye />}
        </div>
        <Form.Control.Feedback type="invalid">
          Please fill a valid password.
        </Form.Control.Feedback>
                </Form.Group>

              </Row>
              <Form.Group className="mb-4">
                <Form.Check
                  required
                  label={t("form_terms_text")}
                  feedback="You must agree before submitting."
                  feedbackType="invalid"
                />
              </Form.Group>
              <Form.Group className="text-center mb-4">
                <Button type="submit">{t("form_signupBtn_text")}</Button>
              </Form.Group>
              <Form.Group className="text-center">
                <p className="paragraph text-md-start">
                  {t("form_have_account_text")}
                  <Link to="/driver-login">
                    <span className="blue-text">{t("form_loginBtn_text")}</span>
                  </Link>
                </p>
              </Form.Group>
            </Form>
          </div>
        </div>
      </section>
      <section className="driveContent blueBg py-5 mb-5 ">
        <Container>
          <Row>
            <Col>
              <h2 className="section-title mb-4 text-white">
                {t("signup_section_title1")}
              </h2>
              <p className="paragraph text-white">
                {t("signup_section_para1")}
              </p>
              <p className="paragraph text-white">
                {t("signup_section_para2")}
                <br />
                <br /> {t("signup_section_para3")}
              </p>
              <div className="btn-wrapper mt-4">
                <button className="book-us-btn mybtns">
                  {t("signup_inquireBtn")}
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </AdminLayout>
  );
};

export default DriverSignup;
