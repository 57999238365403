import { Col, Container, Row } from "react-bootstrap";
import "../../CSS/Contact/index.css";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import AdminLayout from "../../LayoutWrapper/AdminLayout";
import { Image } from "react-bootstrap";
import CanadaIcon from "../../Images/SignupForm/canadaIcon.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MapImg from "../../Images/Contact/map.jpg";

const Contact = () => {
  const { t } = useTranslation();

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  return (
    <AdminLayout>
      <section className="contactBanner py-5">
        <div className="container-fluid">
          <Row className="align-items-center">
            <div className="col-lg-6">
              <div className="contactBannerImg bgImg h-100"></div>
            </div>
            <div className="col-lg-6 mt-lg-0 mt-4">
              <div className="content-Wrapper ps-md-5">
                <h1 className="pageTitle mb-4">{t("page_title_contact")}</h1>
                <p className="paragraph">{t("contact_para1")}</p>
              </div>
            </div>
          </Row>
        </div>
      </section>
      <section className="contactform-map pb-5">
        <div className="container-fluid">
          <Row>
            <div className="col-lg-5 px-md-5 px-4">
              <div className="form-Data-Wrapper">
                <h4 className="contactForm-title text-center mb-4">
                  {t("get_in_touch")}
                </h4>
                <div className="contactForm-wrapper">
                  <div className="form-field-wrapper">
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                    >
                      <Row className="mb-4">
                        <Form.Group as={Col} controlId="validationCustom01">
                          <Form.Control
                            required
                            type="text"
                            placeholder={t("form_fullName_text")}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please fill a fullname.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      <Row className="mb-4">
                        <Form.Group
                          as={Col}
                          controlId="validationCustomUsername"
                        >
                          <InputGroup hasValidation>
                            <Form.Control
                              type="email"
                              placeholder={t("form_email_text")}
                              aria-describedby="inputGroupPrepend"
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Please fill a email.
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Row>
                      <Row className="mb-4">
                        <Form.Group
                          as={Col}
                          controlId="validationCustomUsername"
                        >
                          <InputGroup hasValidation>
                            <InputGroup.Text id="inputGroupPrepend">
                              <Image src={CanadaIcon} alt="" />
                            </InputGroup.Text>
                            <Form.Control
                              type="tel"
                              placeholder={t("form_mobile_text")}
                              aria-describedby="inputGroupPrepend"
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Please fill a mobile number.
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Row>
                      <Row className="mb-4">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Control
                            as="textarea"
                            rows={4}
                            placeholder={t("form_messege_text")}
                          />
                        </Form.Group>
                      </Row>
                      <Form.Group className="text-center mb-4">
                        <Button type="submit">{t("form_sbmtBtn")}</Button>
                      </Form.Group>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 px-md-5 px-4 mt-lg-0 mt-5">
              <h4 className="contactForm-title text-center mb-4">
                {t("get_directions")}
              </h4>
              <div className="mapImg-wrapper">
                <img src={MapImg} alt="" />
              </div>
            </div>
          </Row>
        </div>
      </section>
    </AdminLayout>
  );
};

export default Contact;
