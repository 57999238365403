import React, { useState } from "react";
import Logo from "../../Images/Header/logo.png";
import fb from "../../Images/Footer/facebook.png";
import insta from "../../Images/Footer/instagram.png";
import linkden from "../../Images/Footer/linkedin.png";
import yt from "../../Images/Footer/youtube.png";
import twitter from "../../Images/Footer/twitter.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { postApihandler } from "../../ApiHandler";
import swal from "sweetalert";

function Footer() {
  const { t } = useTranslation();
  const [email,setEmail] = useState("");
  const submitFun = async()=>{
    let data ={
      email:email
    }
     const res = await postApihandler("/addEmailForNewsLetter",data);
     console.log("res==>",res);
     
     if(res.status === 200 && res.data.status === 200){
      swal({
        icon: "success",
        text: "Successfully Send",
      });
      setEmail("");
     }else{
      swal({
        icon: "error",
        text: res.message.data.message,
      });
     }
  }
  return (
    <section className="footer bg-dark py-5 text-white text-lg-start text-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3 mt-lg-0 mt-4">
          <div className="footer-logo-wrapper">
              <Link to="/">
                <img src={Logo} alt="" />
              </Link>
            </div>
          </div>
          <div className="col-lg-3 mt-lg-0 mt-4">
           
            <div className="footer-menu-wrpper footer-menu-wrpper-1 footer-col-1 ">
              <Link to="/about-us">
                <h6 className="footer-menu-title">
                  {t("footer_column1_title")}
                </h6>
              </Link>
              {/* <Link to="#">
                <h6 className="footer-menu">{t("footer_menu1")}</h6>
              </Link> */}
              {/* <Link to="/help-support">
                <h6 className="footer-menu">{t("footer_menu2")}</h6>
              </Link> */}
              <Link to="/contact">
                <h6 className="footer-menu">{t("footer_menu3")}</h6>
              </Link>
              <Link to="/blog">
                <h6 className="footer-menu">{t("footer_menu14")}</h6>
              </Link>
            </div>
          </div>
          <div className="col-lg-3 mt-lg-0 mt-4">
            <div className="footer-menu-wrpper footer-menu-wrpper-1 footer-col-1">
              <Link to="#">
                <h6 className="footer-menu-title">
                  {t("footer_column2_title")}
                </h6>
              </Link>
              <Link to="/privacypolicy">
                <h6 className="footer-menu">
                  {t("page_title_privacy_policy")}
                </h6>
              </Link>
              <Link to="/cookies-policy">
                <h6 className="footer-menu">
                  {t("page_title_cookies_policy")}
                </h6>
              </Link>
              <Link to="/terms-conditions">
                <h6 className="footer-menu">
                  {t("page_title_terms_conditions")}
                </h6>
              </Link>
           
            </div>
          </div>
          <div className="col-lg-3 mt-lg-0 mt-4">
            <div className="footer-col-1 social-box-wrapper">
              <h6 className="footer-menu-title mb-3">
                {t("footer_newsletter_text")}
              </h6>
              <div className="footer-emailBox-wrapper mb-5">
                <input
                  type="email"
                  className="footer-emailBox"
                  placeholder={t("footer_newsletter_inputText")}
                  value={email}
                  onChange={(e)=> setEmail(e.target.value)}
                />
                <button onClick={submitFun} style={{padding:"7px",borderRadius:"12px"}}>Submit</button>
              </div>
              <div className="footer-social-icons-wrapper">
                <img src={fb} alt="" />
                <img src={twitter} alt="" />
                <img src={insta} alt="" />
                <img src={linkden} alt="" />
                <img src={yt} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
