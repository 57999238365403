import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import AdminLayout from "../../LayoutWrapper/AdminLayout";
import ForgetPassword from "../../Images/SignupForm/update-password.png";
import "../../CSS/UserSignup/index.css";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { putApihandler } from "../../ApiHandler";
import swal from "sweetalert";
import { useNavigate, useParams } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const UpdatePasswordDriver = () => {
    let { email } = useParams();
   
  const { t } = useTranslation();
  const {register,handleSubmit,reset}=useForm();
  const history = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [validated, setValidated] = useState(false);

  const onSubmit = async (value) => {
    // console.log("event==>",value);
    // const email = localStorage.getItem("Email");
    // console.log("email====>",email);
    const res = await putApihandler(`/resetDriverPassword/${email}`,value);
    console.log("res-->",res);
    if(res.status === 200){
      swal({
        icon: "success",
        text: "Your password has been changed successfully",
      });  
      reset();
      history("/driver-login")
    }
    setValidated(true);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };
  return (
    <AdminLayout>
      <section className="frgt-pswd-wrapper  py-5 mb-5">
        <div className="forget-img-wrapper text-center">
          <img src={ForgetPassword} alt=""/>
        </div>
        {/* <p className="paragraph text-center">{t("reset_password_tagline")}</p> */}
        <div className="form-Data-Wrapper form-wrapper-column-2 py-4 px-5">
          <div className="form-field-wrapper">
            <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
            <Row className="mb-4">
                <Form.Group
                  as={Col}
                  controlId="validationCustom03"
                  className="password-group"
                >
                  <InputGroup hasValidation>
                    <Form.Control
                      className="password-input"
                      type={showPassword ? "text" : "password"}
                      placeholder={t("form_password_text")}
                      required
                      {...register("password")}
                    />
                    <div
                      className="password-icon"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </div>
                    <Form.Control.Feedback type="invalid">
                      Please fill in your password.
                    </Form.Control.Feedback>
                  </InputGroup>
                  <InputGroup hasValidation className="mt-3">
                    <Form.Control
                      className="password-input"
                      type={showPassword1 ? "text" : "password"}
                      placeholder={t("form_confirm_password_text")}
                      aria-describedby="inputGroupPrepend"
                      required
                      {...register("confirmPassword")}
                    />
                    <div
                      className="password-icon"
                      onClick={togglePasswordVisibility1}
                    >
                      {showPassword1 ? <FaEyeSlash /> : <FaEye />}
                    </div>
                    <Form.Control.Feedback type="invalid">
                      Please fill in your Confirm Password.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Form.Group className="text-center mb-4">
                <Button type="submit">{t("form_sbmtBtn")}</Button>
              </Form.Group>
            </Form>
          </div>
        </div>
      </section>
    </AdminLayout>
  );
};

export default UpdatePasswordDriver;