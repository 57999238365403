import axios from "axios";

export const serverUrl = "https://comfort-trip.com/api";
// export const serverUrl = "http://13.59.201.28/api";

// export const getApihandler = async (endPoint) => {
//   try {
//     const getres = await axios.get(serverUrl + endPoint);
//     return getres.data;
//   } catch (error) {/ 
//     return { message: error.message, status: 400 };
//   }
// };

// export const getbyidApihandler = async (endPoint) => {
//   try {
//     const getres = await axios.get(serverUrl + endPoint);
//     // console.log("getresbyid=>", getres);
//     return getres.data;
//   } catch (error) {
//     return { message: error.message, status: 400 };
//   }
// };

export const postLoginApihandler = async (endPoint, value) => {
  try {
    const postRes = await axios.post(serverUrl + endPoint, value);
    return postRes.data;
  } catch (error) {
    return { message: "email not exits", status: 400 };
  }
};

export const postApihandler = async (endPoint, value) => {
  try {
    const postRes = await axios.post(serverUrl + endPoint, value);
    return postRes;
    // console.log("data is - ", postRes);
  } catch (error) {
    return { message: error.response, status: 403 };
  }
};

// export const deleteApihandler = async (endPoint, id) => {
//   try {
//     const deleteRes = await axios.delete(serverUrl + endPoint + id);
//     return deleteRes.data;
//   } catch (error) {
//     return { message: error.message, status: 403 };
//   }
// };

export const putApihandler = async (endPoint, value) => {
  try {
    const res = await axios.put(serverUrl + endPoint, value);
    return res.data;
  } catch (error) {
    return { error };
  }
};
